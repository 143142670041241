//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "HeaderFormItem",
    props: {
        label: {
            type: String,
            default: ''
        }
    }
}
