//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./list";
import TemplateManage from './templateManage'

export default {
    components: {
        List,
        TemplateManage
    },
    data() {
        return {
            templateVisible: false,
        };
    },
    methods: {
        handlerTemplate(bool) {
            this.templateVisible = bool;
        },
    },
};
