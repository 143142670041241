//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from "@/components/page-header";
import HeaderFormItem from "@/components/page-header/header-form-item";
import environment from "../tobacco-environment/environment";
import quality from "../tobacco-quality/quality";
import {dataUtil} from '../../../utils/data-util';

export default {
	components: {
		HeaderFormItem,
		PageHeader,
		environment,
		quality
	},
	inject: ["hideLoading", "showLoading"],
	computed: {
		areaTree() {
			return this.$store.state['areaManagement'].areaTree;
		},
		changeActiveItem() {
			return this.$store.state['dataManagement'].activeItem;
		},
		types() {
			return this.$store.state['tobaccoEnvironment'].type;
		},
		types1() {
			return this.$store.state['tobaccoQuality'].type;
		},
	},
	data() {
		return {
			dataTypes: [],
			dataType: ['1', '1'],
			show: true,
			areaTreeProps: {
				'value': 'code',
				'label': 'name',
				'children': 'childrenList',
				'checkStrictly': true
			},
			deleteIds: [],
			dateTimes: [],
			delReqVO: {
				type: '',
				ids: []
			},
			year: '',
		};
	},
	async created() {
		// 获取模板类型
		this.dataTypes = await dataUtil.getDataTypes();
		// 获取年份
		this.dateTimes = dataUtil.getDateTimes();
		// 获取行政区域
		await this.$store.dispatch('areaManagement/getAreaTree');
		await this.changeData(this.dataType);
		await this.search();
	},
	methods: {
		/**
		 * 模板管理
		 */
		templateManage() {
			// 将多级联查数据传输过去
			this.$store.dispatch('dataManagement/changeActiveItem', this.dataTypes);
			this.$emit("showTemplateManage", true);
		},

		/**
		 * 查询
		 */
		async search() {
			if (!this.dataType || this.dataType.length == 0) {
				this.$message.warning("请先选择数据类型");
				return;
			}
			this.showLoading();
			await this.$store.dispatch("tobaccoEnvironment/changeExportFlag", false);
			await this.$store.dispatch("tobaccoQuality/changeExportFlag", false);
			await dataUtil.changeParamsSingle("pageNum", 1);
			await this.$store.dispatch("dataManagement/changeSelect", true);
			// 查询
			await dataUtil.search();
			this.hideLoading();
		},

		/**
		 * 批量删除
		 */
		async deleteBatch() {
			// 获取要删除的id列表
			this.delReqVO.ids = await dataUtil.getSelectList();
			this.$confirm(`是否确认删除?`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "error",
			}).then(async () => {
				this.showLoading();
				let changeRes = await this.$store.dispatch("dataManagement/delete", this.delReqVO);
				this.hideLoading();
				let type = "error";
				if (changeRes.code === 200) {
					type = "success";
					await this.search();
				}
				this.$notify({
					title: "删除提示",
					message: changeRes.msg,
					type: type,
					duration: 2000,
					position: "bottom-right",
				});
			}).catch(() => {
			});
		},

		/**
		 * 根据类型修改界面
		 * @param val
		 */
		changeData(val) {
			dataUtil.getType(val).then(item => this.delReqVO.type = item);
			if (val.toString() === "1,1") {
				this.show = false;
			} else {
				this.show = true;
			}
		},

		/**
		 * 更改年份
		 */
		changeYear() {
			dataUtil.changeParamsSingle("year", this.year);
		},

		/**
		 * 更改产地
		 * @param val
		 */
		changeArea(val) {
			dataUtil.changeParamsSingle("areaCode", dataUtil.getAreaName(val, this.areaTree));
		},
	},
};
