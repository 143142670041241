//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from '../../../utils/data-util';
import {tableColumnData} from "../../../utils/table-column-data";

export default {
	components: {},
	inject: ["hideLoading", "showLoading"],
	name: 'quality',
	computed: {
		bindTableColumns: {
			get() {
				let temp = this.$store.state['tobaccoManagement'].columns;
				if (temp && temp.length > 0) {
					return temp.filter(column => column.show);
				}
				return [];
			}
		},
		types() {
			return this.$store.state['tobaccoQuality'].type;
		},
		list() {
			return this.$store.state['tobaccoQuality'].list;
		},
		total() {
			return this.$store.state['tobaccoQuality'].total;
		},
		pageNum() {
			return this.$store.state['tobaccoQuality'].queryParams.pageNum;
		},
		pageSize() {
			return this.$store.state['tobaccoQuality'].queryParams.pageSize;
		},
		select() {
			return this.$store.state['dataManagement'].select;
		}
	},
	data() {
		return {}
	},
	methods: {
		/**
		 * 页面数量变化触发
		 * @param val
		 */
		async handleSizeChange(val) {
			this.showLoading();
			await dataUtil.changeParamsSingle("pageNum", 1);
			await dataUtil.changeParamsSingle("pageSize", val);
			await dataUtil.search();
			this.hideLoading();
		},

		/**
		 * 页码变化触发
		 * @param val
		 */
		async handleCurrentChange(val) {
			this.showLoading();
			await dataUtil.changeParamsSingle("pageNum", val);
			await dataUtil.search();
			this.hideLoading();
		},

		/**
		 * 选中列表
		 */
		handleSelectionChange(val) {
			let lists = [];
			val.forEach(item => {
				lists.push(item.id);
			})
			this.$store.dispatch("tobaccoQuality/changeSelectList", lists);
		},
	},
	created() {
		this.$store.dispatch("tobaccoManagement/changeColumns", tableColumnData[this.types]);
	},
	mounted() {
		this.hideLoading()
	}
}
