//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import URL from "../../../config/index.js";

export default {
    name: "templateManage",
    inject: ["hideLoading", "showLoading"],
    computed: {
        options() {
            return this.$store.state['dataManagement'].activeItem;
        },
    },
    data() {
        return {
            ruleForm: {
                dataType: [],
            },
            rules: {
                dataType: [
                    {type: 'array', required: true, message: '请选择模板类型', trigger: 'change'}
                ],
            },
            name: '',
            count: 0,
            header: {},
            fileList: [],
            uploadUrl: URL.baseUrl + "dataManagement/uploadData"
        }
    },
    created() {
    },
    methods: {
        /**
         * 下载模板
         */
        downLoad() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.showLoading();
                    this.name = '';
                    this.count = 0;
                    this.getName(this.options);
                    this.name += "模板.xlsx";
                    this.$store.dispatch('file/downLoad', {"name": this.name});
                    this.hideLoading();
                } else {
                    return false;
                }
            });
        },

        /**
         * 上传
         */
        submitUpload() {
            this.$confirm("请认真核对模板类型与文件是否匹配，是否确认上传？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                    // 检验是否选择了类型
                    this.$refs["ruleForm"].validate((valid) => {
                        if (!valid) {
                            return false;
                        } else {
                            // 校验文件是否为空
                            if (this.fileList.length == 0) {
                                this.$message("上传文件不能为空，请选择要上传的文件！");
                                return;
                            } else {
                                this.showLoading();
                                this.header.Authorization = JSON.parse(window.localStorage.getItem('qy__JWT_TOKEN')).value;
                                this.$refs.upload.submit();
                            }
                        }
                    });
                }
            ).catch(() => {
            });
        },

        /**
         * 清空
         */
        submitClear() {
            this.$confirm(`是否确认清空列表?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.showLoading();
                this.fileList = [];
                this.hideLoading();
                this.$notify({
                    title: "清空",
                    message: "清空成功",
                    type: "success",
                    duration: 2000,
                    position: "bottom-right",
                });
            }).catch(() => {
            });
        },

        /**
         * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
         */
        onChange(file, fileList) {
            this.fileList = fileList;
        },

        /**
         * 文件列表移除文件时的钩子
         */
        handleRemove(file, fileList) {
            this.fileList = fileList;
        },

        /**
         * 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除
         */
        beforeRemove(file) {
            return this.$confirm(`确定移除${file.name}？`)
        },

        onError() {
            this.hideLoading();
        },

        /**
         * 文件上传成功时的钩子
         */
        successFlag(response) {
            this.hideLoading();
            if (response.code == 200) {
                this.$notify({
                    title: '成功',
                    message: response.msg,
                    type: 'success'
                });
            } else {
                this.$notify({
                    title: '警告',
                    message: response.msg,
                    type: 'warning'
                });
            }
            setTimeout(() => {
                this.fileList = [];
            }, 3000);
        },

        /**
         * 递归获取名称
         */
        getName(val) {
            val.forEach(item => {
                if (item.value === this.ruleForm.dataType[this.count]) {
                    this.name += item.label + '-';
                    this.count++;
                    if (this.ruleForm.dataType[this.count]) {
                        this.getName(item.children);
                    }
                }
            })
        },
        /**
         * 重置规则
         * @param formName
         */
        resetForm() {
            this.$refs["ruleForm"].resetFields();
        }
    },
}
